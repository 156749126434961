export const addItem = (itemId, quantity = 1) => {
  let addCart = JSON.parse(localStorage.getItem('cart')) || [];

  // Mevcut item'i bul
  const itemIndex = addCart.findIndex(cartItem => cartItem.id === itemId);
  
  if (itemIndex > -1) {
    // Mevcut item varsa, miktarı artır
    addCart[itemIndex].quantity += quantity;
  } else {
    // Yeni item'i ekle
    // Burada item'ı bilmediğimiz üçün yalnızca id ile yeni item yaratmaq mümkün deyil.
    // Eğer diğer bilgileri (örneğin, ürün adı, fiyat vb.) biliyorsanız, onları buraya ekleyin.
    addCart.push({ id: itemId, quantity });
  }
  
  localStorage.setItem('cart', JSON.stringify(addCart));
      
  window.dispatchEvent(new Event('cartUpdated'));
};
