export const addWishlist = (itemId, quantity = 1) => {
  let addCart = JSON.parse(localStorage.getItem('wishlist')) || [];
  const existingItemIndex = addCart.findIndex(item => item.id === itemId);

  if (existingItemIndex !== -1) {
    addCart.splice(existingItemIndex, 1);
  } else {
    const newItem = {
      id: itemId,
      quantity: quantity
    };
    addCart.push(newItem);
  }

  localStorage.setItem('wishlist', JSON.stringify(addCart));
  window.dispatchEvent(new Event('wishUpdated'));
};
