import  { createContext} from 'react'

export const ApiLinkContext = createContext()

export const ApiLinkProvider = (props) => {

    const ApiLink = "https://myparfumes.az/api";
    
    return (

        <ApiLinkContext.Provider value={{ApiLink}} >
            {props.children}
        </ApiLinkContext.Provider>
        
    )
}

export default ApiLinkContext;