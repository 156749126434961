import React, { useContext } from "react";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";

const BreadCrumb = ({title}) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <nav className="breadcrumb-section theme1 bg-lighten2 pt-40 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h2 className="title pb-4 text-dark text-capitalize" style={{fontFamily:"Montserrat",fontWeight:"370"}}>
                  {title}
                </h2>
              </div>
            </div>
            <div className="col-12">
              <ol className="breadcrumb bg-transparent m-0 p-0 align-items-center justify-content-center">
                <li className="breadcrumb-item">
                  <a href="index.html">{t.home}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {title}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default BreadCrumb;
