import React, { useState, useEffect, useContext } from "react";
import BreadCrumb from "../../utils/BreadCrumb";
import { addItem } from "../../utils/addtocart";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import axios from "axios";
import ApiLinkContext from "../../context/ApiLinkContext";

import { Toaster, toast } from "sonner";
const Wishlist = () => {
  const { ApiLink } = useContext(ApiLinkContext);
  const [localWish, setLocalWish] = useState([]);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setLocalWish(fetchWishlist);
  }, []);

  const handleQuantity = (productId, increment) => {
    const updatedWishList = [...localWish];
    const wishItemIndex = updatedWishList.findIndex(
      (item) => item.id === productId
    );

    if (wishItemIndex > -1) {
      const newQuantity = updatedWishList[wishItemIndex].quantity + increment;

      if (newQuantity >= 1) {
        updatedWishList[wishItemIndex].quantity = newQuantity;
        setLocalWish(updatedWishList);
        localStorage.setItem("wishlist", JSON.stringify(updatedWishList));
      }
    }
  };

  const handleRemoveItem = (index) => {
    const removeItem = localWish.filter((_, idx) => idx !== index);
    setLocalWish(removeItem);
    localStorage.setItem("wishlist", JSON.stringify(removeItem));
    window.dispatchEvent(new Event("wishUpdated"));
  };
  const [product, setProduct] = useState([]);
  const [brand, setBrand] = useState([]);
  const [accord, setAccord] = useState([]);
  const [tag, setTag] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${ApiLink}/products.php`),
      axios.get(`${ApiLink}/brand.php`),
      axios.get(`${ApiLink}/tag.php`),
      axios.get(`${ApiLink}/accord.php`),
    ])
      .then(([productsRes, brandsRes, tagsRes, accordsRes]) => {
        const productRes = productsRes.data;
        const brandRes = brandsRes.data;
        const tagRes = tagsRes.data;
        const accordRes = accordsRes.data;
        setTag(tagRes);
        setProduct(productRes);
        setBrand(brandRes);
        setAccord(accordRes);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const findProduct = product.filter((item) =>
    localWish.some((wishItem) => wishItem.id == item.id)
  );

  const combinedProducts = findProduct.map((prod) => {
    const wishItem = localWish.find((wish) => wish.id === prod.id);
    const findBrand = brand && brand.find((brand) => brand.id == prod.brand);
    const findAccord = accord.find((accord) => accord.id == prod.accord);
    const findSex =
      prod &&
      prod.sex.length > 0 &&
      (prod.sex === "1"
        ? `${t.man}`
        : prod.sex === "2"
        ? `${t.woman}`
        : prod.sex === "3"
        ? `${t.unisex}`
        : "Unknown");
    const tagNames = prod.tag
      .map((tagId) => {
        const tagItem = tag.find((t) => t.id == tagId);
        return tagItem ? tagItem.tName : null;
      })
      .filter(Boolean);
    return {
      ...prod,
      quantity: wishItem ? wishItem.quantity : 0,
      brand: findBrand && findBrand.bName,
      accord: findAccord && findAccord.aName,
      sex: findSex,
      tag: tagNames,
    };
  });

  return (
    <>
      <BreadCrumb title={t.wishlist} />
      {localWish && localWish.length > 0 ? (
        <section className="whish-list-section theme1 pt-80 pb-80 ">
          <div className="container " id="wishlistTable">
            <div className="row">
              <div className="col-12">
                <h3 className="title mb-30 pb-25 text-capitalize">
                  {t.wishlist}
                </h3>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center" scope="col">
                          {t.productName}
                        </th>
                        <th className="text-center" scope="col">
                          {t.productName1}
                        </th>
                        <th className="text-center" scope="col">
                          {t.quantity}
                        </th>
                        <th className="text-center" scope="col">
                          {t.price}
                        </th>
                        <th className="text-center" scope="col">
                          {t.delete}
                        </th>
                        <th className="text-center" scope="col">
                          {t.cart}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {combinedProducts.length > 0 &&
                        combinedProducts.map((item, index) => (
                          <tr key={index}>
                            {item.img && item.img.length > 0 && (
                              <td className="text-center">
                                <img src={item.img[0]} alt="img" />
                              </td>
                            )}

                            <td className="text-center">
                              <span>{item.name}</span>
                            </td>
                            <td className="text-center">
                              <div className="product-count style">
                                <div className="count d-flex justify-content-center">
                                  <input
                                    type="number"
                                    min={1}
                                    max={10}
                                    step={1}
                                    value={item.quantity}
                                    readOnly
                                  />
                                  <div className="button-group">
                                    <button
                                      className="count-btn increment"
                                      onClick={() => handleQuantity(item.id, 1)}
                                    >
                                      <i className="fas fa-chevron-up" />
                                    </button>
                                    <button
                                      className="count-btn decrement"
                                      onClick={() =>
                                        handleQuantity(item.id, -1)
                                      }
                                    >
                                      <i className="fas fa-chevron-down" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <span className="whish-list-price">
                                {" "}
                                {item.quantity * item.price} ₼{" "}
                              </span>
                            </td>
                            <td className="text-center">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveItem(index)}
                              >
                                <span className="trash">
                                  <i className="fas fa-trash-alt" />
                                </span>
                              </a>
                            </td>
                            <td className="text-center">
                              <a
                                className="btn btn-dark btn--lg"
                                onClick={() => addItem(item.id, item.quantity)}
                              >
                                + {t.addToCart}
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="wishlistTableMobile">
            <div className="row">
              {combinedProducts.length > 0 &&
                combinedProducts.map((item, index) => (
                  <div className="col-12">
                    <Toaster position="top-right" richColors />
                    <div className="d-flex justify-content-end mt-3">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveItem(index)}
                      >
                        <span className="trash">
                          <i className="fas fa-trash-alt" />
                        </span>
                      </a>
                    </div>
                    <div className="d-flex justify-content-center">
                      {item.img && item.img.length > 0 && (
                        <img src={item.img[0]} alt="img" className="img-fluid" style={{maxWidth:"70%"}} />
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6>{t.productName1}</h6>
                      <h6>{item.name}</h6>
                    </div>
                    <div className="d-flex justify-content-between my-3">
                      <h6>{t.price}</h6>
                      <h6>{item.quantity * item.price} ₼</h6>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <div className="product-count style">
                        <div className="count d-flex justify-content-center">
                          <input
                            type="number"
                            min={1}
                            max={10}
                            step={1}
                            value={item.quantity}
                            readOnly
                          />
                          <div className="button-group">
                            <button
                              className="count-btn increment"
                              onClick={() => handleQuantity(item.id, 1)}
                            >
                              <i className="fas fa-chevron-up" />
                            </button>
                            <button
                              className="count-btn decrement"
                              onClick={() => handleQuantity(item.id, -1)}
                            >
                              <i className="fas fa-chevron-down" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <a
                        className="btn btn-dark btn--lg"
                        onClick={() => {
                          addItem(item.id, item.quantity);
                          toast.success(
                            `${t.cart + ":"} ${item.name}-${
                              item.volume + "ML"
                            }`,
                            { duration: 700 }
                          );
                        }}
                      >
                        {t.addToCart}
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <h4 className="text-center py-5">{t.emptywishlist}</h4>
      )}
    </>
  );
};

export default Wishlist;
