import React from "react";
import AppRouter from "./router/AppRouter";
import { LanguageProvider } from "./context/LanguageContext";
import { ApiLinkProvider } from "./context/ApiLinkContext";

const App = () => {
  return (
    <>
      <ApiLinkProvider>
        <LanguageProvider>
          <AppRouter />
        </LanguageProvider>
      </ApiLinkProvider>
    </>
  );
};

export default App;
