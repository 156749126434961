import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../utils/BreadCrumb";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import axios from "axios";
import {ApiLinkContext} from "../../context/ApiLinkContext" ;
const Checkout = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language]
  const {ApiLink} = useContext(ApiLinkContext)
  const [product,setProduct] = useState([])
  const [radio,setRadio] = useState('store')
  const localCart = JSON.parse(localStorage.getItem("cart")) || [];


  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };

  useEffect(() => {
    axios.get(`${ApiLink}/products.php`)
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0 && Array.isArray(data)) {
          const localCartIds = localCart.map((item) => item.id);
          const findData = data.filter((item) => localCartIds.includes(item.id))
            .map((item) => {
              const findLocal = localCart.find((i) => i.id == item.id);
              return {
                ...item, 
                quantity: findLocal ? findLocal.quantity : 0 
              };
            });
  
          setProduct(findData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  const totalPrice = product.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
 

  return (
    <>
      <BreadCrumb title={`${t.order.toUpperCase()}`}/>
      <div className="check-out-section pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="billing-info-wrap">
                <h3 className="title">{t.billing}</h3>
                <form
                  className="personal-information"
                  action="https://htmldemo.net/looki/looki/assets/php/contact.php"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>{t.firstName},{t.lastName} <span className="text-danger" style={{fontWeight:"bold"}}>*</span></label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="billing-info mb-20px">
                        <label>{t.phone} <span className="text-danger" style={{fontWeight:"bold"}}>*</span></label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="billing-select mb-20px">
                        <label htmlFor="inputState" className="form-label">
                          {t.email}
                        </label>
                         <input type="email" name="" id="" />
                      </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                      <div className="billing-info-radio mb-20px">
                        <label>{t.delivery}</label>
                        <div className="col-lg-12">
                          <label>{t.radio}</label>
                          <br />
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="store-radio"
                              type="radio"
                              name="radio"
                              onChange={handleRadioChange}
                              value="store"
                              checked={radio === "store"}
                              className="radio"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="store-radio"
                              className="ms-2"
                            >
                              {t.radio1}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-4">
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="store-address"
                              type="radio"
                              name="radio"
                              onChange={handleRadioChange}
                              value="address"
                              checked={radio === "address"}
                              className="radio"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="store-address"
                              className="ms-2"
                            >
                              {t.radio2}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                        {radio == "address" && (
                          <div className="col-lg-12 mt-3">
                            <div className="billing-info  ">
                              <label>
                                {t.address} <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                // value={address}
                                name="address"
                                placeholder={`${t.inputP}`}
                                // onChange={(e) => {
                                //   setAddress(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5 mt-4 mt-lg-0">
              <div className="your-order-area">
                <h3 className="title">{t.yourOrder}</h3>
                <div className="your-order-wrap gray-bg-4">
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>{t.product1}</li>
                        <li>{t.price}</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        {product &&
                          product.length > 0 &&
                          product.map((i) => (
                            <li>
                              <span className="order-middle-left">
                                {i.name} <span style={{fontWeight:"500"}}>x</span> {i.quantity}
                              </span>
                              <span className="order-price">{i.quantity * i.price} ₼ </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="your-order-total">
                      <ul>
                        <li className="order-total">{t.total}</li>
                        <li>{totalPrice.toFixed(2)} ₼</li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="Place-order mt-25">
                  <a className="btn btn--xl btn-block btn-primary" href="#">
                    {t.lastOrder}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
