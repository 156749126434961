import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/logo1.svg.jpg";
import { ApiLinkContext } from "../context/ApiLinkContext";
import axios from "axios";
import slugify from "react-slugify";
const Footer = () => {
  const { ApiLink } = useContext(ApiLinkContext);
  const date = new Date();
  const currentYear = date.getFullYear();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [sex, setSex] = useState([]);
  useEffect(() => {
    axios(`${ApiLink}/products.php`)
      .then((res) => {
        setSex(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const uniqueSex = [...new Set(sex.map((item) => item.sex))].sort(
    (a, b) => a - b
  );

  const replaceSpecialChars = (str) => {
    return str
      .replace(/ş/g, "s")
      .replace(/ə/g, "e")
      .replace(/ı/g, "i")
      .replace(/ğ/g, "g")
      .replace(/ç/g, "c")
      .replace(/ö/g, "o")
      .replace(/ü/g, "u")
      .replace(/ /g, "-"); // Boşluqları da tire ilə əvəz et
  };
  return (
    <>
      <footer className="bg-white theme1 position-relative">
        {/* footer bottom start */}
        <div className="footer-bottom pt-80 pb-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 col-lg-4 mb-30">
                <div className="footer-widget mx-w-400">
                  {/* <div className="footer-logo mb-25">
                    <a href="#">
                      <img
                        src={logo}
                        alt="footer logo"
                        className="img-fluid w-50"
                      />
                    </a>
                  </div> */}
                  <p className="text mb-30">
                    {t.address} : {t.contactAddress}
                    <br />
                    {t.email}:{" "}
                    <a href="mailto:info@myperfume.az">info@myperfume.az</a>
                    <br />
                    {t.phone}:{" "}
                    <a href="https://wa.me/994508055511" target="_blank">
                      +994 50 805 55 11
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-2 mb-30">
                <div className="footer-widget">
                  <ul className="footer-menu">
                    {uniqueSex.map((item, index) => {
                      const changeSex =
                        item === "1" ? t.man : item === "2" ? t.woman : "";
                      const slug = slugify(`${changeSex}`);

                      return (
                        <li key={index}>
                          <NavLink
                            to={`/perfume/${`${item}-${slug}`}`}
                            style={{ fontSize: "18px" }}
                          >
                            {changeSex}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-2 mb-30">
                <div className="footer-widget">
                  <ul className="footer-menu">
                    <li>
                      <Link
                        to="/about"
                        onClick={() => window.scrollTo({ top: 0 })}
                        style={{ fontSize: "18px" }}
                      >
                        {t.about2}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        onClick={() => window.scrollTo({ top: 0 })}
                        style={{ fontSize: "18px" }}
                      >
                        {t.contact1}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-30">
                <div className="footer-widget">
                  <ul className="footer-menu">
                    <li>
                      <a href="#" style={{ fontSize: "18px" }}>
                        {t.terms}
                      </a>
                    </li>
                    <li>
                      <a href="#" style={{ fontSize: "18px" }}>
                        {t.terms1}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coppy-right bg-dark py-15">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12 order-last order-md-first">
                <div className="text-md-start text-center mt-3 mt-md-0">
                  <p className="text-center">
                    © Copyright {currentYear}{" "}
                    <a href="https://myperfume.az" target="_blank">
                      MyPerfume.az
                    </a>
                    .{t.copyright}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
