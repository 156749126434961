import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { addItem } from "../../utils/addtocart";
import { addWishlist } from "../../utils/addtowishlist";
import ModalView from "../cart/ModalView";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";

import { Toaster, toast } from "sonner";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import man from "../../assets/img/man.jpg";
import woman from "../../assets/img/woman.jpg";

import manicon from "../../assets/img/manicon.png";
import womanicon from "../../assets/img/womanicon.jpg";
import unisexicon from "../../assets/img/unisexicon.png";
const Discount = ({ setSelectedData }) => {
  const [localWish, setLocalWish] = useState(
    () => JSON.parse(localStorage.getItem("wishlist")) || []
  );
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { ApiLink } = useContext(ApiLinkContext);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    Promise.all([axios.get(`${ApiLink}/products.php`)]).then(
      ([productsRes]) => {
        const productRes = productsRes.data;
        setProducts(productRes);
      }
    );
  }, []);

  useEffect(() => {
    const handleWishlistUpdate = () => {
      const fetchWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setLocalWish(fetchWishlist);
    };

    window.addEventListener("wishUpdated", handleWishlistUpdate);

    // Cleanup
    return () => {
      window.removeEventListener("wishUpdated", handleWishlistUpdate);
    };
  }, []);

  const visibleProductsCount = products.filter((item) => item.dis > 0).length;

  const shouldEnableNavigation = () => {
    return visibleProductsCount > 0 && visibleProductsCount > 4;
  };

  const uniqueSex = [...new Set(products.map((item) => item.sex))];

  return (
    <>
      <Toaster position="top-right" richColors />
      <div className="container-fluid">
      {
        man && woman  && (
          <div className="row g-3">
          
          {uniqueSex
            .filter((item) => item === "1" || item === "2")
            .map((item, index) => {
              const changeSex =
                item === "1" ? t.man : item === "2" ? t.woman : "";
                const slug = slugify(`${changeSex}`);
              return (
                <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                  <NavLink to={`/perfume/${`${item}-${slug}`}`}>
                    <div className="categoryContainer">
                      <div className="imgBox">
                        <img
                          src={index === 0 ? man : woman}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="outline">
                        <div className="title">{changeSex}</div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
        </div>
        )
      }
      </div>

      <section className="theme1 bg-white pb-80 py-5">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="section-title text-center">
                <h2 className="title pb-3">{t.dis1}</h2>
              </div>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={3}
              navigation={shouldEnableNavigation()}
              loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              modules={[Navigation]}
              className="mySwiper"
            >
              {products
                .filter((item) => item.dis > 0)
                .map((i) => {
                  const active = localWish.find((item) => item.id == i.id);
                  return (
                    <SwiperSlide>
                      <div className="col-lg-12 col-md-12 col-sm-6 col-12 mb-5">
                        <div className="product-slider-init theme1 slick-nav">
                          <div className="slider-item">
                            <div className="card product-card">
                              <div className="card-body p-0">
                                <div className="media flex-column">
                                  <div className="product-thumbnail position-relative">
                                  <span
                                          className="badge top-right"
                                          style={{ left: "0" }}
                                        >
                                          <img
                                            src={`${
                                              i.sex == "1"
                                                ? manicon
                                                : i.sex == "2"
                                                ? womanicon
                                                : unisexicon
                                            }`}
                                            alt=""
                                            className="img-fluid"
                                            style={{
                                              position: "absolute",
                                              zIndex: "1",
                                              right: "0",
                                              width:
                                                i.sex == "1"
                                                  ? "20px"
                                                  : "25px",
                                            }}
                                          />
                                        </span>
                                    <span
                                      className={`badge badge-danger  top-left ${
                                        i.dis > 0 ? "d-block" : "d-none"
                                      }`}
                                    >
                                      {t.dis}
                                    </span>
                                    <NavLink
                                      to={`/${slugify(
                                        `${i.id}-${i.name}`
                                      ).toLowerCase()}`}
                                    >
                                      {i.img && i.img.length > 0 && (
                                        <>
                                          <img
                                            src={i.img[0]}
                                            alt="thumbnail"
                                            className="first-img img-fluid"
                                            style={{ width: "100%" }}
                                          />
                                          <img
                                            src={i.img[1]}
                                            alt="thumbnail"
                                            className="second-img img-fluid"
                                            style={{ width: "100%" }}
                                          />
                                        </>
                                      )}
                                    </NavLink>

                                    <ul className="actions d-flex justify-content-center">
                                      <li>
                                        <a
                                          className={`action ${
                                            active ? "activeWish" : ""
                                          }`}
                                          onClick={() => addWishlist(i.id)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-placement="bottom"
                                            title="add to wishlist"
                                            className="icon-heart"
                                          ></span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="action"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick-view"
                                          onClick={() => {
                                            setSelectedData(i);
                                          }}
                                        >
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Quick view"
                                            className="icon-magnifier"
                                          />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="media-body">
                                    <div className="product-desc">
                                      <h3 className="title">
                                        <a>{i.name}</a>
                                      </h3>
                                      <div className="star-rating">
                                        <span className="ion-ios-star" />
                                        <span className="ion-ios-star" />
                                        <span className="ion-ios-star" />
                                        <span className="ion-ios-star" />
                                        <span className="ion-ios-star de-selected" />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span className="product-price">
                                          <del
                                            className="del"
                                            style={{
                                              textDecoration: `${
                                                i.dis > 0
                                                  ? "line-through"
                                                  : "none"
                                              }`,
                                            }}
                                          >
                                            {i.price} ₼
                                          </del>
                                          <span
                                            className={`onsale ms-2 ${
                                              i.dis > 0 ? "" : "d-none"
                                            }`}
                                          >
                                            {i.dis} ₼
                                          </span>
                                        </span>
                                        <button
                                          className="pro-btn "
                                          onClick={() => {
                                            addItem(i.id);
                                            toast.success(
                                              `${t.cart + ":"} ${i.name}-${
                                                i.volume + "ML"
                                              }`,
                                              { duration: 700 }
                                            );
                                          }}
                                        >
                                          <i className="icon-basket" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Discount;
