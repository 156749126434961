import React, { useContext, useState } from "react";
import BreadCrumb from "../utils/BreadCrumb";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const Contact = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <BreadCrumb title={t.contact} />
      <Helmet>
        <title>{t.contact}</title>
      </Helmet>
      <div>
        <section className="contact-section pt-80 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-30">
                <div className="contact-page-side-content">
                  <h3 className="contact-page-title">{t.contact}</h3>
                  <div className="single-contact-block">
                    <h4>
                      <i className="fa fa-fax me-2" /> {t.address}
                    </h4>
                    <p>{t.contactAddress}</p>
                  </div>
                  <div className="single-contact-block">
                    <h4>
                      <i className="fa fa-phone me-2" /> {t.phone}
                    </h4>
                    <p>
                      <a href="tel:+994508055511">
                        {t.phone}: +994 50 805 55 11
                      </a>
                    </p>
                  </div>
                  <div className="single-contact-block">
                    <h4>
                      <i className="fas fa-envelope me-2" /> {t.email}
                    </h4>
                    <p>
                      <a href="mailto:info@myperfume.az">
                      info@myperfume.az
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 mb-30">
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10217.876961402166!2d49.88486534869194!3d40.41343636586063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403063f2367dfdb3%3A0xce6752a9956f76dd!2sChinar%20Plaza!5e0!3m2!1saz!2saz!4v1728366760224!5m2!1saz!2saz"
                    width={600}
                    height={100}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
