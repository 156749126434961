import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ModalWish from "../pages/home/ModalWish";
import ModalCart from "../pages/home/ModalCart";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";
import logo from "../assets/img/parfumee.png";
import axios from "axios";
import ApiLinkContext from "../context/ApiLinkContext";
import slugify from "react-slugify";
const Header = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { ApiLink } = useContext(ApiLinkContext);
  const [openWish, setOpenWish] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [active, setActive] = useState(false);
  const [brand, setBrand] = useState([]);
  const [product, setProduct] = useState([]);
  const handleOpenWish = () => {
    setOpenWish((prevState) => !prevState);
  };
  const handleOpenCart = () => {
    setOpenCart((prevState) => !prevState);
  };
  const [cart, setCart] = useState([]);
  const [wish, setWish] = useState([]);
  const updateWish = () => {
    const localWish = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWish(localWish);
  };

  const updateCart = () => {
    const localCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(localCart);
  };

  useEffect(() => {
    updateWish();
    window.addEventListener("wishUpdated", updateWish);
    return () => {
      window.removeEventListener("wishUpdated", updateWish);
    };
  }, []);

  useEffect(() => {
    updateCart();
    window.addEventListener("cartUpdated", updateCart);
    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);
  useEffect(() => {
    Promise.all([
      axios(`${ApiLink}/brand.php`),
      axios(`${ApiLink}/products.php`),
    ])
      .then(([brandRes, productRes]) => {
        const brandData = brandRes.data;
        const productData = productRes.data;
        setProduct(productData);
        setBrand(brandData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // const filteredProducts = product.filter(
  //   (item) => item && (item.sex === "1" || item.sex === "2" || item.sex === "3")
  // );

  // const uniqueSexNames = [
  //   ...new Set(
  //     filteredProducts.map((item) => {
  //       if (item.sex === "1") return `${t.man}`;
  //       if (item.sex === "2") return `${t.woman}`;
  //       if (item.sex === "3") return `${t.unisex}`;
  //     })
  //   ),
  // ];
  const sortedBrands = brand
    .filter((item) => product.some((i) => i.brand == item.id))
    .sort((a, b) => a.bName.localeCompare(b.bName));

  // Brendləri hərf üzrə qruplaşdırırıq
  const groupedBrands = sortedBrands.reduce((acc, item) => {
    const firstLetter = item.bName.charAt(0).toUpperCase(); // Hərfi böyük etmək
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});
  return (
    <>
      <div id="sticky" className="header-middle theme1 py-15 py-lg-0">
        <div className="container position-relative my-3">
          <div className="row align-items-center">
            <div className="col-6 col-lg-2 col-xl-2">
              <div className="logo ">
                <Link
                  to="/"
                
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid webLogo w-50"
                  />
                  {}
                </Link>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 d-none d-lg-block">
              <ul className="main-menu d-flex justify-content-center">
                <li className=" ml-0">
                  <NavLink to="/" activeClassName="active" className="ps-0">
                    {t.home}
                  </NavLink>
                </li>
                {/* <li className="position-static">
                  <NavLink to="/perfume" activeClassName="active">
                    {t.product} <i className="ion-ios-arrow-down" />
                  </NavLink>
                  <ul className="mega-menu row">
                    <li className="mb-4">
                      <NavLink to="/perfume">Bütün Ətirlər</NavLink>
                    </li>
                    <hr />
                    {uniqueSexNames.slice(0, 3).reverse().map((sexName, index) => (
                      <li className="mega-menu-title col-4"  key={index}>
                        <a href="#" style={{fontWeight:"bold"}}>{sexName}</a>
                      </li>
                    ))}

                    {filteredProducts.slice(0,24).reverse().map((item) => (
                      <li className="col-4" key={item.id}>
                        <ul>
                          <li>
                            <NavLink to={`${slugify(`${item.id}-${item.name}`).toLowerCase()}`} style={{fontSize:"15px"}}>{item.name} {item.volume} ML</NavLink>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li> */}
                <li className="position-static">
                  <NavLink to="/perfume" activeClassName="active">
                    {t.brand} <i className="ion-ios-arrow-down" />
                  </NavLink>
                  <ul
                    className="mega-menu row"
                    style={{ height: "400px", overflowY: "scroll" }}
                  >
                    {/* <li className="mb-4">
                      <NavLink to="/perfume">Bütün Brendlər</NavLink>
                    </li> */}
                    {/* <hr /> */}
                    <div className="container">
                      <div className="row">
                        {Object.keys(groupedBrands).map((letter) => (
                          <>
                            <div className="col-3">
                              <h6
                                style={{ color: "#00AFD5" }}
                                id="letter"
                                className="my-3"
                              >
                                {letter}
                              </h6>
                              {groupedBrands[letter]
                                .filter((i) =>
                                  product.some(
                                    (product) => product.brand === i.id
                                  )
                                )
                                .map((item) => {
                                  const productCount = product.filter(
                                    (product) => product.brand === item.id
                                  ).length;
                                  return (
                                    <NavLink
                                      to={`/perfume/${slugify(
                                        `${item.id}-${item.bName}`
                                      ).toLowerCase()}`}
                                      className="mt-3"
                                      id="brendNav"
                                      activeClassName="active"
                                    >
                                      {item.bName} ({productCount})
                                    </NavLink>
                                  );
                                })}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </ul>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/about">
                    {t.about}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">
                    {t.contact}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-6 col-lg-3 col-xl-2">
              {/* search-form end */}
              <div className="d-flex align-items-center justify-content-end">
                {/* static-media end */}
                <div className="cart-block-links theme1 d-none d-sm-block">
                  <ul className="d-flex">
                    <li>
                      <a
                        className="offcanvas-toggle"
                        href="#"
                        onClick={() => handleOpenWish()}
                      >
                        <span className="position-relative">
                          <i className="icon-heart" />
                          <span className="badge cbdg1">
                            {" "}
                            {wish && wish.length > 0 ? wish.length : 0}
                          </span>
                        </span>
                      </a>
                    </li>
                    <li className="mr-xl-0 cart-block position-relative">
                      <a
                        className="offcanvas-toggle"
                        href="#"
                        onClick={() => handleOpenCart()}
                      >
                        <span className="position-relative">
                          <i className="icon-bag" />
                          <span className="badge cbdg1">
                            {cart && cart.length > 0 ? cart.length : 0}
                          </span>
                        </span>
                      </a>
                    </li>
                    {/* cart block end */}
                  </ul>
                </div>
                <div className="mobile-menu-toggle theme1 d-lg-none">
                  <a
                    onClick={() => setActive(!active)}
                    style={{ cursor: "pointer" }}
                    className={`offcanvas-toggle ${active ? "close" : ""}`}
                  >
                    <svg viewBox="0 0 700 550">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="offcanvas-overlay"
        onClick={() => setActive(false)}
        style={{ display: `${active ? "block" : "none"}` }}
      ></div>

      <div
        id="offcanvas-mobile-menu"
        className={`offcanvas theme1 offcanvas-mobile-menu ${
          active ? "offcanvas-open" : ""
        }`}
      >
        <div className="inner">
          <div className="border-botton mb-4 pb-4 d-flex  align-items-center justify-content-between">
            <div className="mobileLogo">
              <img src={logo} alt="logo" className="img-fluid w-50" />
            </div>
            <button
              className="offcanvas-close"
              onClick={() => setActive(false)}
            >
              ×
            </button>
          </div>

          <nav className="offcanvas-menu">
            <ul>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/"
                  onClick={() => setActive(false)}
                >
                  <span className="menu-text">{t.home}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/perfume"
                  onClick={() => setActive(false)}
                >
                  <span className="menu-text">{t.product}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" onClick={() => setActive(false)}>
                  <span className="menu-text">{t.about}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/contact"
                  onClick={() => setActive(false)}
                >
                  {t.contact}
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="offcanvas-head mb-4">
            <nav className="offcanvas-top-nav  ">
              <ul className="d-flex flex-wrap flex-column">
                <li className="my-2 mx-2">
                  <NavLink
                    activeClassName="active"
                    to="/wishlist"
                    onClick={() => setActive(false)}
                  >
                    <i className="ion-android-favorite-outline" /> {t.wishlist}
                    <span className="ms-2">
                      ({wish && wish.length > 0 ? wish.length : 0})
                    </span>
                  </NavLink>
                </li>
                <li className="my-2 mx-2">
                  <NavLink
                    activeClassName="active"
                    to="/cart"
                    onClick={() => setActive(false)}
                  >
                    <i className="icon-bag" /> {t.cart}
                    <span>({cart && cart.length > 0 ? cart.length : 0})</span>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="offcanvas-social py-30">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/myparfumes.az/"
                  target="_blank"
                >
                  <i className="icon-social-instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="icon-social-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalWish isOpen={openWish} onToggle={handleOpenWish} />
      <ModalCart isOpen={openCart} onToggle={handleOpenCart} />
    </>
  );
};

export default Header;
