import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import ApiLinkContext from "../../context/ApiLinkContext";
import axios from "axios";

const ModalWish = ({ isOpen, onToggle }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [wish, setWish] = useState([]);
  const {ApiLink} = useContext(ApiLinkContext)
  const [findData,setFindData] = useState([])

  const updateWish = () => {
    const localWish = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWish(localWish);
  };

  useEffect(() => {
    updateWish();
    window.addEventListener("wishUpdated", updateWish);
    return () => {
      window.addEventListener("wishUpdated", updateWish);
    };
  }, []);
  useEffect(()=>{
    axios.get(`${ApiLink}/products.php`)
    .then((res)=>{
      const productData = res.data
      const findData = productData && productData.filter((item)=>{
       return wish.some((i)=>i.id == item.id)
      })
      setFindData(findData)
    })
    .catch((err)=>{
      console.log(err);
    })
  },[wish])

  const handleWishRemove = (index) => {
    const removeItem = wish.filter((_, idx) => idx !== index);
    localStorage.setItem("wishlist", JSON.stringify(removeItem));
    window.dispatchEvent(new Event("wishUpdated"));
  };
  return (
    <>
      <div
        class="offcanvas-overlay"
        onClick={() => onToggle(false)}
        style={{ display: `${isOpen ? "block" : "none"}` }}
      ></div>
      <div
        id="offcanvas-wishlist"
        className={`offcanvas offcanvas-wishlist theme1 ${
          isOpen ? "show" : ""
        }`}
      >
        <div className="inner">
          <div className="head d-flex flex-wrap justify-content-between">
            <span className="title">{t.wishlist}</span>
            <button className="offcanvas-close" onClick={() => onToggle(false)}>
              ×
            </button>
          </div>
          <ul className="minicart-product-list">
            {findData && findData.length > 0 ? (
              findData.map((i, index) => (
                <li>
                  <a className="image">
                  {
                    i.img && i.img.length > 0 && (
                      <img
                      src={i.img[0]}
                      alt="Cart product Image"
                      className="img-fluid"
                    />
                    )
                  }
                  </a>
                  <div className="content">
                    <a className="title">{i.name}</a>
                    <span className="quantity-price">
                      1 x <span className="amount">{i.price} ₼</span>
                    </span>
                    <a
                      className="remove"
                      onClick={() => handleWishRemove(index)}
                      style={{ cursor: "pointer" }}
                    >
                      ×
                    </a>
                  </div>
                </li>
              ))
            ) : (
              <h5 className="text-start">{t.emptywishlist}</h5>
            )}
          </ul>
          {wish && wish.length > 0 ? (
            <Link
              to="/wishlist"
              onClick={() => onToggle(false)}
              className="btn btn-secondary btn--lg d-block d-sm-inline-block mt-30"
            >
              {t.wishlist}
            </Link>
          ) : (
            <Link
              to="/perfume"
              onClick={() => onToggle(false)}
              className="btn btn-secondary btn--lg d-block d-sm-inline-block mt-30"
            >
              {t.product}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalWish;
