import React, { useContext } from "react";
import BreadCrumb from "../utils/BreadCrumb";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const About = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <BreadCrumb title={t.about} />
      <Helmet>
        <title>{t.about}</title>
      </Helmet>
      <section className="about-section pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-11 mx-auto mb-30">
              <div className="about-content ">
                <div>
                  <p className="mb-30 fs-6"  dangerouslySetInnerHTML={{__html:t.aboutDesc}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
